import React, { useState, useEffect } from "react";
import "./NameApprovalModal.css";
import Header from "../../component/Header";
import moment from "moment";
import { MdClose } from "react-icons/md";
import NameApprovalModal from "./NameApprovalModal";
import { getListNameApprovalsData } from "../../redux/action/NameApproval/NameApprovalAction";
import { useSelector, useDispatch } from "react-redux";

const NameApprovalByAdmin = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalActionType, setModalActionType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const merchantList = useSelector(
    (state) => state.NameApprovalData.NameApprovelsListData
  );

  useEffect(() => {
    dispatch(getListNameApprovalsData(currentPage));
  }, [dispatch, currentPage]);


  const handleActionClick = (action, item, event) => {
   console.log("hhhhhhhhhhhhhaaaaaaaaaaaa");
    setModalActionType(action);
    setSelectedItem(item);
    setIsModalOpen(true); 
  };

  const handleConfirmAction = () => {
    console.log(`${modalActionType} confirmed for item:`, selectedItem);
    setIsModalOpen(false);
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= merchantList.totalPage) {
      setCurrentPage(pageNumber);
      dispatch(getListNameApprovalsData({ page: pageNumber }));
    }
  };

  const renderTableData = () => {
    if (
      !merchantList ||
      !merchantList.result ||
      merchantList.result.length === 0
    ) {
      return (
        <tr>
          <td colSpan="6">No data available</td>
        </tr>
      );
    }

    return merchantList.result.map((item, index) => {
      const {
        id,
        username,
        currentUserName,
        companyName,
        currentCompanyName,
        requestStatus,
        createdAt,
        accountType,
        oldCompanyName,
        oldUserName,
      } = item;

      return (
        <tr key={id}>
          <td>
            <div className="date">
              {moment(createdAt).format("LL")}
              <span>{moment(createdAt).format("HH:mm A")}</span>
            </div>
          </td>
          <td>
            <div className="company">{accountType || "N/A"}</div>
          </td>
          <td>
            <div className="sender">
              <span className="sender-txt">
                {accountType === "Business" ? oldCompanyName : oldUserName}
              </span>
            </div>
          </td>

          <td>
            <div className="sender">
              <span className="sender-txt">
                {accountType === "Business" ? companyName : username}
              </span>
            </div>
          </td>
          <td>{requestStatus}</td>

          <td>
            <span className="act-btn">
              <a
                href="javascript:void(0)"
                onClick={(event) => handleActionClick("approve", item, event)}
              >
                <i className="icon-icon-tick" />
              </a>
              <a
                href="javascript:void(0)"
                onClick={(event) => handleActionClick("decline", item, event)}
                className="close-btn ml-2"
              >
                <MdClose />
              </a>
            </span>
          </td>
        </tr>
      );
    });
  };

  const nextPaginationHander = () => {
    if (currentPage < merchantList.totalPage) {
      const p = currentPage + 1;
      setCurrentPage(p);
      dispatch(getListNameApprovalsData({ page: p }));
    }
  };

  const previousPaginationHander = () => {
    if (currentPage > 1) {
      const p = currentPage - 1;
      setCurrentPage(p);
      dispatch(getListNameApprovalsData({ page: p }));
    }
  };

  const firstPaginationHander = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
      dispatch(getListNameApprovalsData({ page: 1 }));
    }
  };

  const lastPaginationHander = () => {
    if (currentPage !== merchantList.totalPage) {
      setCurrentPage(merchantList.totalPage);
      dispatch(getListNameApprovalsData({ page: merchantList.totalPage }));
    }
  };

  const paginationList = () => {
    const pageNumbers = [];
    for (let i = 1; i <= merchantList.totalPage; i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map((number) => {
      if (number === currentPage - 2 || number === currentPage + 2) {
        return <span key={number}>...</span>;
      } else if (
        number < 2 ||
        number === pageNumbers.length ||
        merchantList?.currentPage === number ||
        merchantList?.currentPage === number - 1 ||
        merchantList?.currentPage === number + 1
      ) {
        return (
          <li key={number} onClick={() => handlePageChange(number)}>
            <a className={merchantList?.currentPage === number ? "active" : ""}>
              {number}
            </a>
          </li>
        );
      }
      return null;
    });

    return (
      <ul>
        <li>
          <a className="nxt" onClick={() => firstPaginationHander()}>
            <i className="fa fa-angle-double-left" aria-hidden="true" />
          </a>
        </li>
        <li>
          <a className="nxt" onClick={() => previousPaginationHander()}>
            <i className="fa fa-angle-left" aria-hidden="true" />
          </a>
        </li>
        {renderPageNumbers}
        <li>
          <a
            className="nxt"
            onClick={() => nextPaginationHander(merchantList.totalPage)}
          >
            <i className="fa fa-angle-right" aria-hidden="true" />
          </a>
        </li>
        <li>
          <a
            className="nxt"
            onClick={() => lastPaginationHander(merchantList.totalPage)}
          >
            <i className="fa fa-angle-double-right" aria-hidden="true" />
          </a>
        </li>
      </ul>
    );
  };

  return (
    <div>
      <Header />
      <NameApprovalModal
        isModalOpen={isModalOpen}
        onClick={handleConfirmAction}
        approvalId={selectedItem?.id}
        actionType={modalActionType}
        currentPage={currentPage}
      />

      <section className="dash-wrap">
        <div className="sec-block alt first">
          <div className="block-single auto">
            <div className="block-heading mb-3">
              <h2>Name Approvals</h2>
            </div>
            <div className="transaction-main">
              <div className="transaction-table">
                <div className="table-responsive">
                  <table className="table theme-table">
                    <tbody>
                      <tr>
                        <th>Date</th>
                        <th>Account Type</th>
                        <th>Old Name</th>
                        <th>New Name</th>
                        <th>Request Status</th>
                        <th style={{ width: "140px" }}>Action</th>
                      </tr>
                      {renderTableData()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="site-pagination">{paginationList()}</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NameApprovalByAdmin;
