import React, { useRef } from "react";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  getListNameApprovalsData,
  nameApprovelPermissionData,
} from "../../redux/action/NameApproval/NameApprovalAction";

const NameApprovalModal = ({
  isModalOpen,
  onClick,
  approvalId,
  actionType,
  currentPage,
}) => {
  const dispatch = useDispatch();
  // const bodyRef = useRef(null);

  // Function to close the modal
  const handleCloseModal = () => {
    console.log("modal close");
    onClick(false);
    // bodyRef.current.focus();
  };

  const handleApprovalAction = () => {
    // Dispatch the approval action
    dispatch(
      nameApprovelPermissionData({
        id: approvalId,
        requestStatus: "APPROVED", // Send 'APPROVED' status
      })
    )
      .then((response) => {
        console.log(response, "ressssssssssss");
        dispatch(getListNameApprovalsData(currentPage));
        handleCloseModal();
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrr");
        handleCloseModal();
      });
  };

  const handleDeclineAction = () => {
    dispatch(
      nameApprovelPermissionData({
        id: approvalId,
        requestStatus: "DECLINED", // Send 'REJECTED' status
      })
    )
      .then((response) => {
        console.log(response, "decline response");
        dispatch(getListNameApprovalsData(currentPage));
        handleCloseModal(); // Close the modal after rejection
      })
      .catch((error) => {
        console.log(error, "error in rejection");
        handleCloseModal();
      });
  };
  const handleYesClick = () => {
    if (actionType === "approve") {
      handleApprovalAction();
    } else if (actionType === "decline") {
      handleDeclineAction();
    }
  };
  return (
    <Modal
      isOpen={isModalOpen}
      ariaHideApp={false}
      onRequestClose={handleCloseModal}
    >
      <div>
        <div className="modal-dialog modal-dialog-centered common-modal size-2">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={handleCloseModal}
              >
                <MdClose />
              </button>
            </div>
            <div className="modal-body">
              {/* Confirmation Message */}
              <p>Are you sure you want to {actionType} this request?</p>

              <div style={{ marginBottom: "15px" }}></div>

              <div className="row modified" style={{ float: "right" }}>
                <div className="col-sm-6">
                  <button
                    // onClick={handleApprovalAction}
                    onClick={handleYesClick}
                    className="btn btn-block green-btn"
                    type="button"
                    style={{
                      padding: "8px 15px",
                      fontSize: "14px",
                      marginRight: "10px",
                    }}
                  >
                    Yes
                  </button>
                </div>
                <div className="col-sm-6">
                  <button
                    onClick={handleCloseModal}
                    className="btn btn-block red-btn"
                    type="button"
                    style={{
                      padding: "8px 15px",
                      fontSize: "14px",
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NameApprovalModal;
